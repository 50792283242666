import React, { Component, createContext } from "react";
import Intro from "./Intro";
import Welcome from "./Welcome";
import AxisSelection from "./AxisSelection";
import BeforeBegin from "./BeforeBegin";
import Quiz from "./Quiz";
import ResultGraph from "./ResultGraph";
import About from "./About";
import axis1 from "./data/axis1";
import axis2 from "./data/axis2";
import intro_messages from "./data/introSteps";

const axisObj = {
  axis1: axis1,
  axis2: axis2,
};

export const GoToStepContext = createContext();

export class Wizard extends Component {
  state = {
    step: 1,
    lastStep: 0,
    intro_step: 0,
    intro_end: intro_messages.length,
    axisSelected: "axis1",
    quiz_step: 0,
    quiz_end: 0,
    surveyData: [],
  };

  nextStep = () => {
    const { step, intro_step, intro_end } = this.state;
    if (intro_step < intro_end - 1) {
      this.setState({
        intro_step: intro_step + 1,
      });
    } else {
      this.setState({
        step: step + 1,
      });
    }
  };

  nextQuizStep = () => {
    const { step, quiz_step, quiz_end } = this.state;
    if (quiz_step < quiz_end - 1) {
      this.setState({
        quiz_step: quiz_step + 1,
      });
    } else if (step === 3) {
      this.setState({
        step: 1,
        intro_step: 0,
        intro_end: intro_messages.length,
        quiz_step: 0,
      });
    } else {
      this.setState({
        step: step + 1,
      });
    }
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  goToStep = (toLastStep, step) => {
    if (toLastStep) {
      this.setState({ step: this.state.lastStep, lastStep: 0 });
    } else {
      this.setState({ step: step, lastStep: this.state.step });
    }
  };

  handleChange = (value) => {
    const data = this.state.surveyData;
    data[this.state.quiz_step].selected = Number(value);
    this.setState({
      surveyData: data,
    });
  };

  handleAxisSelection = (axisOption) => {
    const quizData = axisObj[axisOption].baseData;
    this.setState({
      axisSelected: axisOption,
      quiz_end: quizData.length,
      quiz_step: 0,
      surveyData: quizData,
      lastStep: 0,
    });
  };

  render() {
    const { step, lastStep } = this.state;
    const { surveyData } = this.state;
    const message = intro_messages[this.state.intro_step];
    const question = this.state.surveyData[this.state.quiz_step];
    const {
      name,
      title,
      beforeBeginText,
      resultGraphText,
      initialFilters,
      baseData,
      graphDomain,
    } = axisObj[this.state.axisSelected];

    return (
      <GoToStepContext.Provider
        value={{ goToStep: this.goToStep, lastStep: lastStep, step: step }}
      >
        {step === 1 ? (
          <Intro nextStep={this.nextStep} message={message} />
        ) : step === 2 ? (
          <Welcome nextStep={this.nextStep} />
        ) : step === 3 ? (
          <AxisSelection
            nextStep={this.nextStep}
            handleAxisSelection={this.handleAxisSelection}
          />
        ) : step === 4 ? (
          <BeforeBegin
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            axisName={title}
            text={beforeBeginText}
          />
        ) : step === 5 ? (
          <Quiz
            quizStep={this.state.quiz_step}
            nextStep={this.nextQuizStep}
            handleChange={this.handleChange}
            axisName={name}
            values={surveyData}
            question={question.question}
            options={question.options}
            graphDomain={graphDomain}
          />
        ) : step === 6 ? (
          <ResultGraph
            nextStep={this.nextQuizStep}
            initialFilters={initialFilters}
            values={surveyData}
            test={baseData}
            axisName={name}
            text={resultGraphText}
            graphDomain={graphDomain}
          />
        ) : step === 7 ? (
          <About />
        ) : (
          ""
        )}
      </GoToStepContext.Provider>
    );
  }
}

export default Wizard;
