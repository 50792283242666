import React, { useContext, useRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import borderUp from "./../images/border-up.svg";
import borderDown from "./../images/border-down.svg";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { GoToStepContext } from "./Wizard";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    height: "100vh",
    boxSizing: "border-box",
    overflow: "auto",
    overflowX: "hidden",
  },
  buttonContainer: { position: "absolute", right: 8, top: 20 },
  button: {
    borderRadius: 16,
    fontSize: 16,
    minWidth: "min-content",
    height: 32,
    border: `1px solid ${theme.palette.primary.dark}`,
    padding: "8px 12px 10px",
    margin: 4,
  },
  "@media (min-width: 600px)": {
    outerContainer: {
      border: `24px solid ${theme.palette.primary.dark}`,
    },
    innerContainer: {
      position: "relative",
      height: "calc(100vh - 240px)",
      width: "calc(100vw - 48px)",
    },
  },
}));

export default function Layout({ children }) {
  const classes = useStyles();
  const isNotMobile = useMediaQuery("(min-width:600px)");
  const { goToStep, lastStep, step } = useContext(GoToStepContext);

  return (
    <>
      {isNotMobile ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.outerContainer}
        >
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.innerContainer}
          >
            {children}
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid className={classes.buttonContainer}>
            {lastStep !== 0 && (
              <Button
                variant="outlined"
                className={classes.button}
                onClick={() => {
                  goToStep(true);
                }}
              >
                back
              </Button>
            )}
            {step !== 3 && (
              <Button
                variant="outlined"
                className={classes.button}
                onClick={() => {
                  goToStep(false, 3);
                }}
              >
                menu
              </Button>
            )}
            <Button
              variant="outlined"
              className={classes.button}
              style={{ textTransform: "lowercase", width: 32 }}
              onClick={() => {
                goToStep(false, 7);
              }}
            >
              i
            </Button>
          </Grid>
          <Grid container>{children}</Grid>
        </>
      )}
    </>
  );
}

const firstContainerStyles = makeStyles((theme) => ({
  firstContainer: {
    position: "relative",
    textAlign: "left",
    border: `1px solid ${theme.palette.primary.dark}`,
    margin: "88px auto 16px",
    width: "calc(100% - 28px)",
    padding: 24,
  },
  secondContainer: { marginTop: 24 },
  border: {
    position: "absolute",
    width: "calc(100% + 2px)",
    left: -1,
  },
  "@media (min-width: 600px)": {
    firstContainer: {
      position: "relative",
      maxWidth: 400,
      height: 400,
      margin: 0,
      marginRight: 50,
      flexBasis: "auto",
      top: 0,
      "&:after": {
        content: '""',
        position: "absolute",
        right: -32,
        top: 0,
        width: 12,
        height: "100%",
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}));

function FirstContainer({ children, secondContainer }) {
  const classes = firstContainerStyles();
  const borderRef = useRef(null);
  const [borderHeight, setBorderHeight] = useState(15);

  useEffect(() => {
    document.onreadystatechange = () => {
      setBorderHeight(borderRef.current.clientHeight);
    };
  }, [borderRef]);

  return (
    <Grid
      container
      item
      sm={6}
      direction="column"
      alignItems="flex-start"
      className={`${classes.firstContainer} ${
        secondContainer && classes.secondContainer
      }`}
    >
      <img
        ref={borderRef}
        src={borderUp}
        className={classes.border}
        style={{ top: -borderHeight }}
      />
      <div>{children}</div>
    </Grid>
  );
}

const firstContainerScrollableStyles = makeStyles((theme) => ({
  "@media (min-width: 600px)": {
    firstContainer: {
      position: "relative",
      height: 400,
      maxWidth: 432,
      marginRight: 20,
      flexBasis: "auto",
      borderRight: `1px solid ${theme.palette.primary.dark}`,
      top: 0,
      textAlign: "left",
    },
    scrollableContainer: {
      position: "relative",
      height: "calc(100% - 1px)",
      overflowY: "scroll",
      overflowX: "hidden",
      padding: 24,
      scrollbarColor: `${theme.palette.primary.light} transparent`,
      "&::-webkit-scrollbar": {
        width: 10,
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 5,
        backgroundColor: "white",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "black",
      },
    },
    containerBorder: {
      position: "absolute",
      top: 0,
      left: 0,
      width: 400,
      height: "calc(100% - 2px)",
      border: `1px solid ${theme.palette.primary.dark}`,
    },
    scrollbarBorder: {
      position: "absolute",
      top: 0,
      right: 10,
      height: "100%",
      borderRight: `1px solid ${theme.palette.primary.dark}`,
    },
    border: {
      position: "absolute",
      width: "calc(100% + 2px)",
      left: -1,
      top: -17,
    },
  },
}));

function FirstContainerScrollable({ children, secondContainer }) {
  const classes = firstContainerScrollableStyles();
  const isNotMobile = useMediaQuery("(min-width:600px)");

  return (
    <>
      {isNotMobile ? (
        <div className={classes.firstContainer}>
          <div className={classes.containerBorder}>
            <img src={borderUp} className={classes.border} />
          </div>
          <div className={classes.scrollbarBorder} />
          <Grid
            container
            item
            direction="column"
            alignItems="flex-start"
            className={classes.scrollableContainer}
          >
            <div>{children}</div>
          </Grid>
        </div>
      ) : (
        <FirstContainer secondContainer={secondContainer}>
          {children}
        </FirstContainer>
      )}
    </>
  );
}

const secondContainerStyles = makeStyles((theme) => ({
  secondContainer: {
    position: "relative",
    textAlign: "left",
    border: `1px solid ${theme.palette.primary.dark}`,
    margin: "0px auto 24px",
    width: "calc(100% - 28px)",
    padding: 0,
  },
  firstContainer: { marginTop: 76, order: -1 },
  border: {
    position: "absolute",
    width: "calc(100% + 2px)",
    left: -1,
  },
  axis: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "calc(100% - 40px)",
    "&:before": {
      content: '""',
      position: "absolute",
      height: "calc(100% - 40px)",
      left: "50%",
      top: 20,
      borderRight: `1px dashed black`,
    },
    "&:after": {
      content: '""',
      position: "absolute",
      width: "calc(100% - 40px)",
      left: 20,
      top: "50%",
      borderTop: `1px dashed black`,
    },
  },
  opresion: { position: "absolute", top: 18, right: 192 },
  fromOthers: {
    position: "absolute",
    top: 182,
    left: 20,
    width: 88,
    textAlign: "left",
  },
  toSelf: {
    position: "absolute",
    top: 182,
    right: 20,
    width: 88,
    textAlign: "right",
  },
  depresion: { position: "absolute", bottom: 20, left: 193 },
  arrow: { width: 13, height: 13, transform: "translateY(2px)" },
  axisText: { fontSize: 13 },
  bold: { fontFamily: "DIN W01 Bold" },
  "@media (min-width: 600px)": {
    secondContainer: {
      position: "relative",
      order: 2,
      maxWidth: 400,
      height: 400,
      flexBasis: "auto",
      margin: 0,
    },
    buttonContainer: { position: "absolute", right: 0, top: -32 },
    button: {
      borderRadius: 10,
      fontSize: 10,
      minWidth: "min-content",
      height: 20,
      border: `1px solid ${theme.palette.primary.dark}`,
      padding: 8,
      margin: 4,
    },
    axisText: { fontSize: 11 },
    axis: {
      height: "100%",
      "&:before": {
        height: "calc(100% - 80px)",
        top: 40,
      },
    },
    fromOthers: {
      top: 210,
      width: 132,
    },
    toSelf: {
      top: 210,
      width: 132,
    },
  },
}));

function SecondContainer({ children, showAxis, firstContainer }) {
  const classes = secondContainerStyles();
  const { goToStep, lastStep, step } = useContext(GoToStepContext);
  const isNotMobile = useMediaQuery("(min-width:600px)");

  const borderRef = useRef(null);
  const [borderHeight, setBorderHeight] = useState(15);

  useEffect(() => {
    document.onreadystatechange = () => {
      setBorderHeight(borderRef.current.clientHeight);
    };
  }, [borderRef]);

  return (
    <Grid
      container
      item
      alignItems="flex-start"
      className={`${classes.secondContainer} ${
        firstContainer && classes.firstContainer
      }`}
    >
      <img
        ref={borderRef}
        src={borderDown}
        className={classes.border}
        style={{ bottom: -borderHeight }}
      />
      {showAxis && (
        <div className={classes.axis}>
          <Typography className={`${classes.opresion} ${classes.axisText}`}>
            OPPRESSION
            <KeyboardArrowUpIcon className={classes.arrow} />
          </Typography>
          <Typography className={`${classes.fromOthers} ${classes.axisText}`}>
            <KeyboardArrowLeftIcon className={classes.arrow} />
            OPPRESSIVE BEHAVIOUR FROM{" "}
            <strong className={classes.bold}>OTHERS</strong>
          </Typography>
          <Typography className={`${classes.toSelf} ${classes.axisText}`}>
            OPPRESSIVE BEHAVIOUR TO{" "}
            <strong className={classes.bold}>SELF</strong>
            <KeyboardArrowRightIcon className={classes.arrow} />
          </Typography>
          <Typography className={`${classes.depresion} ${classes.axisText}`}>
            <KeyboardArrowDownIcon className={classes.arrow} /> DEPRESSION
          </Typography>
        </div>
      )}
      {children}
      {isNotMobile && (
        <Grid className={classes.buttonContainer}>
          {lastStep !== 0 && (
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => {
                goToStep(true);
              }}
            >
              back
            </Button>
          )}
          {step !== 3 && (
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => {
                goToStep(false, 3);
              }}
            >
              menu
            </Button>
          )}
          <Button
            variant="outlined"
            className={classes.button}
            style={{ textTransform: "lowercase" }}
            onClick={() => {
              goToStep(false, 7);
            }}
          >
            i
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export { FirstContainer, FirstContainerScrollable, SecondContainer };
