import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Layout, { FirstContainerScrollable, SecondContainer } from "./Layout";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const contactBoxStyles = makeStyles((theme) => ({
  contactBox: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    padding: "6px 8px",
    marginTop: 20,
    width: "calc(100% + 8px)",
    "& a": {
      color: theme.palette.primary.contrastText,
    },
  },
  "@media (min-width: 600px)": {
    contactBox: { width: "calc(100% - 36px)", marginTop: 0 },
  },
}));

function ContactBox() {
  const classes = contactBoxStyles();

  return (
    <div className={classes.contactBox}>
      <Typography>Want to commision an Axis?</Typography>
      <Typography>or talk about this?</Typography>
      <Typography>
        <a href="mailto:tomayax.pc@gmail.com,mabarca@toroarquitectos.com">
          CONTACT US
        </a>
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  textWithBackground: {
    backgroundColor: "#F7F7F8",
    width: "calc(100% + 24px)",
    transform: "translateX(-24px)",
    padding: "8px 0 8px 24px",
    margin: "8px 0 12px",
  },
  secondContainer: { padding: 24, textAlign: "left" },
  checkbox: {
    width: 22,
    height: 22,
    marginRight: 12,
    marginBottom: 6,
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${theme.palette.primary.dark}`,
  },
  icon: { width: 20, height: "auto", color: "black", fontWeight: 600 },
  secondContainerText: { width: "100% " },
  lineBreak: { marginBottom: 14 },
  "@media (min-width: 600px)": {
    textWithBackground: {
      width: 374,
      transform: "translateX(-23px)",
    },
    checkbox: { marginBottom: 0, width: 12, height: 12 },
    icon: { width: 14 },
    firstContainerText: { paddingRight: 44 },
    secondContainerText: { width: 352 },
  },
}));

export default function About() {
  const classes = useStyles();
  const isNotMobile = useMediaQuery("(min-width:600px)");

  return (
    <Layout>
      <FirstContainerScrollable>
        <Typography variant="h6">
          THE AXIS OF MODERN VIOLENCE, REMINDS:
        </Typography>
        <Typography variant="subtitle1" className={classes.textWithBackground}>
          A PROJECT BY:
        </Typography>
        <div className={classes.lineBreak}>
          <Grid container alignItems="center">
            <Grid item sm={6}>
              {[
                "Marco Abarca",
                "Olga Casellas Badillo",
                "Luis Roca Iguina",
              ].map((name) => (
                <Grid container>
                  <div className={classes.checkbox}>
                    <CloseIcon className={classes.icon} />
                  </div>
                  <Typography>{name}</Typography>
                </Grid>
              ))}
            </Grid>
            {isNotMobile && (
              <Grid item sm={6}>
                <ContactBox />
              </Grid>
            )}
          </Grid>
        </div>
        <Typography gutterBottom>SPECIAL THANKS:</Typography>
        <Typography className={classes.firstContainerText} gutterBottom>
          First and foremost, thanks to Alia Farid. An invitation to think, is
          the most wonderful invitation. Walking the landscape with you has been
          a pleasure. To SI’s own Alison Coplan, thanks so much for all the
          advice and collaboration. 
        </Typography>
        <Typography className={classes.firstContainerText} gutterBottom>
          To the people who think and struggle with us, while we cook, drive,
          and do laundry {"<3"}. Jose Javier Toro, Agustina Ferreyra, Beatriz
          Santiago Muñoz, Marisa Oliver, Doris Sommer, Alexis Martinez, Joel
          “Yoyo” Rodriguez, Gabriela Suau, Julia Morandeira, Tuto Gonzalez, Olga
          Badillo, Malvina Linares, Sofia Bastidas, Ramón Miranda Beltrán, Felix
          Sanchez, Papiruki, José Luis Pagán y los que nos encontramos en los
          peajes.
        </Typography>
        <Typography className={classes.firstContainerText} gutterBottom>And for inspiration Ernst Cassirer, Emmanuel
          Levinas, Byung-Chul Han, Paul Ricoeur.</Typography>
        {!isNotMobile && <ContactBox />}
      </FirstContainerScrollable>
      <SecondContainer>
        <div className={classes.secondContainer}>
          <Typography>MARCO ABARCA:</Typography>
          <Typography
            className={`${classes.secondContainerText} ${classes.lineBreak}`}
            gutterBottom
          >
            Born in Costa Rica, lives in Puerto Rico. Member of the National Commission on Ethics and Human Values (Costa Rica). Founder of Aula Verde, research interests are in rights-based approaches to human and social development. Holds a MSt in International Human Rights Law (University of Oxford).
          </Typography>
          <Typography>OLGA CASELLAS BADILLO:</Typography>
          <Typography className={classes.secondContainerText}>
            Lives and works in San Juan, Puerto Rico.
          </Typography>
          <Typography className={classes.secondContainerText}>
            {"> "} Founder and Creative Director, Tiguere Corp, design studio
          </Typography>
          <Typography className={classes.secondContainerText}>
            {"> "} Founder, Tomayax, post-capital
          </Typography>
          <Typography
            className={`${classes.secondContainerText} ${classes.lineBreak}`}
          >
            {"> "} Partner, Wild Wind Prod.
          </Typography>
          <Typography>LUIS ROCA IGUINA:</Typography>
          <Typography className={classes.secondContainerText}>
            Lives, works and codes in San Juan, Puerto Rico.
          </Typography>
        </div>
      </SecondContainer>
    </Layout>
  );
}
