export default function findOffset(height, domain, isNotMobile) {
  const range = domain[1] - domain[0];
  const positiveAreaHeightPercentage = domain[1] / range;
  const heightWithoutPadding = height - 10;
  const xAxisPosition = heightWithoutPadding / 2; //Position from top
  const GraphXAxisPosition =
    heightWithoutPadding * positiveAreaHeightPercentage; //Position from top
  const xAxisOffset =
    xAxisPosition - GraphXAxisPosition + (isNotMobile ? 43 : 0);

  return xAxisOffset;
}
