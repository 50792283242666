import React, { useState } from "react";
import { Markup } from "interweave";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import ButtonBase from "@material-ui/core/ButtonBase";
import CloseIcon from "@material-ui/icons/Close";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import _ from "underscore";
import { startCase } from "lodash";
import { ComposedChart, Line, Area, XAxis, YAxis, Tooltip } from "recharts";
import Layout, { FirstContainerScrollable, SecondContainer } from "./Layout";
import findGraphOffset from "./../utils/findGraphOffset";

const useCheckboxStyles = makeStyles((theme) => ({
  checkbox: {
    width: 20,
    height: 20,
    marginBottom: 6,
    backgroundColor: "white",
    marginRight: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: { padding: 4, borderRadius: "50%" },
  icon: { width: 22, height: "auto", color: "black", fontWeight: 600 },
  bold: { fontFamily: "DIN W01 Bold" },
  "@media (min-width: 600px)": {
    checkbox: { marginBottom: 0, width: 12, height: 12 },
    icon: { width: 14 },
  },
}));

function Checkbox({ checked, onClick, color }) {
  const classes = useCheckboxStyles();

  return (
    <ButtonBase className={classes.button} onClick={onClick}>
      <div
        className={classes.checkbox}
        style={{ border: `1px solid ${color}` }}
      >
        {checked && <CloseIcon className={classes.icon} />}
      </div>
    </ButtonBase>
  );
}

const useCheckboxContainerStyles = makeStyles((theme) => ({
  checkboxContainer: {
    width: "calc(100% - 28px)",
    border: `1px solid ${theme.palette.primary.dark}`,
    "& span": { textTransform: "capitalize" },
    margin: "0px auto 8px",
    padding: 8,
  },
  "@media (min-width: 600px)": {
    checkboxContainer: {
      width: "calc(100% - 44px)",
      border: "none",
      margin: "0px 0px 8px",
    },
  },
}));

function CheckboxContainer({ filters, handleAllClick, findColor, setFilters }) {
  const classes = useCheckboxContainerStyles();
  const isNotMobile = useMediaQuery("(min-width:600px)");

  return (
    <Grid container className={classes.checkboxContainer}>
      <Grid container item xs={6} justify="flex-start" alignItems="center">
        <Checkbox
          checked={Object.keys(filters).every((filter) => filters[filter])}
          onClick={handleAllClick}
          color={findColor("all")}
        />
        <Typography variant="button">{"all"}</Typography>
      </Grid>
      {Object.keys(filters).map((filter) => (
        <Grid
          container
          item
          key={filter}
          xs={6}
          justify="flex-start"
          alignItems="center"
        >
          <Checkbox
            checked={filters[filter]}
            onClick={() => {
              const newFilters = { ...filters };
              newFilters[filter] = !newFilters[filter];
              setFilters(newFilters);
            }}
            color={findColor(filter)}
          />
          <Typography variant="button">
            {startCase(
              isNotMobile
                ? filter
                : filter.replace("students", "").replace("sexually", "")
            )}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  container: { width: "100%", paddingBottom: 40 },
  textToAddBackground: {
    width: "100%",
    transform: "translateX(-24px)",
    padding: "8px 24px 8px 24px",
    margin: "8px 0 12px",
    "& a": {
      color: "black",
    },
  },
  textWithBackground: { backgroundColor: "#F7F7F8" },
  instruction: { position: "absolute", bottom: 8, left: 8, fontSize: 12 },
  chart: {
    margin: "auto",
    zIndex: 100,
    "& div": {
      transform: "translateX(+35px)",
    },
    "& p": {
      whiteSpace: "normal",
    },
  },
  "@media (min-width: 600px)": {
    container: { paddingBottom: 0 },
    textToAddBackground: {
      width: 352,
      transform: "translateX(-23px)",
    },
    instruction: { position: "absolute", bottom: 8, left: 8, fontSize: 14 },
  },
}));

export default function ResultGraph({
  initialFilters,
  values,
  test,
  axisName,
  text,
  graphDomain,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const isNotMobile = useMediaQuery("(min-width:600px)");
  const [filters, setFilters] = useState(() => initialFilters);
  const graphHeight = isNotMobile ? 312 : 340;

  //Add user answers to static data
  test.forEach((t) => {
    var obj = _.find(values, function (obj) {
      return obj.question === t.question;
    });
    t.user = obj.selected;
  });

  //Find color for the chart and buttons
  const findColor = (filter) => {
    let color = "";

    if (filter === "all") {
      color = theme.palette.primary.dark;
    } else if (filter === "you") {
      color = "red";
    } else {
      color = `hsl(${
        (310 / (Object.keys(filters).length - 1)) *
          Object.keys(filters).findIndex((item) => item === filter) +
        50
      }, 50%, 50%)`;
    }
    return color;
  };

  const handleAllClick = () => {
    const keys = Object.keys(filters);
    const checked = keys.every((filter) => filters[filter]);
    let newFilters = { ...filters };
    if (checked) {
      keys.forEach((filter) => {
        newFilters[filter] = false;
      });
      newFilters.you = true;
    } else {
      keys.forEach((filter) => {
        newFilters[filter] = true;
      });
    }
    setFilters(newFilters);
  };

  return (
    <Layout>
      {/*////////// FILTER OPTIONS ////////*/}
      {!isNotMobile && (
        <CheckboxContainer
          filters={filters}
          handleAllClick={handleAllClick}
          findColor={findColor}
          setFilters={setFilters}
        />
      )}
      <FirstContainerScrollable secondContainer>
        <Typography variant="h6">
          THE AXIS OF MODERN VIOLENCE, REMINDS:
        </Typography>
        <Typography
          variant="subtitle1"
          className={`${classes.textToAddBackground} ${classes.textWithBackground}`}
        >
          {axisName}
        </Typography>
        {isNotMobile && (
          <CheckboxContainer
            filters={filters}
            handleAllClick={handleAllClick}
            findColor={findColor}
            setFilters={setFilters}
          />
        )}
        <Typography>
          <strong className={classes.bold}>^ PLAY</strong> with the landscape +
          identity groups.{" "}
        </Typography>
        <Typography
          className={`${classes.textToAddBackground} ${
            isNotMobile && classes.textWithBackground
          }`}
          style={{ marginBottom: 12 }}
        >
          {text.map((paragraph, i) => {
            return paragraph.includes("<") ? (
              <Markup content={paragraph} />
            ) : (
              <p key={i}>{paragraph}</p>
            );
          })}
        </Typography>
        <Link href="/" style={{ textDecoration: "underline" }}>
          <Typography variant="h6">Start Over</Typography>
        </Link>
      </FirstContainerScrollable>
      {/*////////// GRAPH ////////*/}
      <SecondContainer showAxis firstContainer>
        <div className={classes.container}>
          <ComposedChart
            style={{
              transform: `translateY(${findGraphOffset(
                graphHeight,
                graphDomain,
                isNotMobile
              )}px)`,
            }}
            className={classes.chart}
            width={isNotMobile ? 380 : window.innerWidth - 60}
            height={graphHeight}
            data={test}
          >
            <Tooltip
              separator=""
              wrapperStyle={{
                width: isNotMobile ? 380 : window.innerWidth - 60,
                position: "absolute",
                left: -40,
                top: 0,
                opacity: 0.85,
              }}
              formatter={(value, name) => [
                "",
                name === "user" ? "You" : startCase(name),
              ]}
            />
            <XAxis hide dataKey="question" />
            <YAxis hide domain={graphDomain} />
            {Object.keys(filters).map((item) => {
              if (filters[item]) {
                return (
                  <Area
                    key={item}
                    type="linear"
                    dataKey={item}
                    stroke={findColor(item)}
                    fill={findColor(item)}
                    fillOpacity="0.3"
                    isAnimationActive={false}
                  />
                );
              } else {
                return "";
              }
            })}
            {filters.you && (
              <Line
                type="linear"
                dataKey="user"
                stroke="red"
                strokeWidth="1.5"
                isAnimationActive={false}
              />
            )}
          </ComposedChart>
        </div>
        <Typography className={classes.instruction}>
          *hover over dots for details
        </Typography>
      </SecondContainer>
    </Layout>
  );
}
