import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Layout, { FirstContainer, SecondContainer } from "./Layout";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  container: { paddingBottom: 92 },
  textWithBackground: {
    backgroundColor: "#F7F7F8",
    width: "100%",
    transform: "translateX(-24px)",
    padding: "8px 24px 8px 24px",
    margin: "8px 0",
  },
  text: { width: "100%" },
  buttonContainer: { position: "absolute", left: 24, bottom: 24 },
  button: {
    borderRadius: 0,
    border: `1px solid ${theme.palette.secondary.main}`,
    maxWidth: 140,
    margin: "4px 8px 6px 0",
    padding: "2px 0 2px 4px",
    justifyContent: "flex-start",
  },
  blackButton: { border: `1px solid ${theme.palette.primary.dark}` },
  label: { width: "min-content", lineHeight: 1 },
  "@media (min-width: 600px)": {
    textWithBackground: {
      width: 374,
      padding: "8px 0 8px 24px",
    },
    text: { width: 314 },
    buttonContainer: { bottom: 88 },
    button: {
      maxWidth: 100,
    },
  },
}));

export default function BeforeBegin({ nextStep, prevStep, axisName, text }) {
  const classes = useStyles();
  const isNotMobile = useMediaQuery("(min-width:600px)");

  return (
    <Layout>
      <FirstContainer>
        <div className={classes.container}>
          <Typography variant="h6">BEFORE YOU BEGIN:</Typography>
          <Typography
            variant="subtitle1"
            className={classes.textWithBackground}
          >
            {axisName}
          </Typography>
          {text.map(({ text, bold }, i) => (
            <Typography
              key={i}
              variant={bold ? "body2" : "body1"}
              className={classes.text}
              gutterBottom
            >
              {text}
            </Typography>
          ))}
          <Grid
            container
            direction="column"
            className={classes.buttonContainer}
          >
            <Button
              variant="outlined"
              className={classes.button}
              classes={{ label: classes.label }}
              onClick={nextStep}
            >
              START
            </Button>
            <Button
              variant="outlined"
              className={`${classes.button} ${classes.blackButton}`}
              classes={{ label: classes.label }}
              onClick={prevStep}
            >
              ABORT
            </Button>
          </Grid>
        </div>
      </FirstContainer>
      {isNotMobile && <SecondContainer showAxis />}
    </Layout>
  );
}
