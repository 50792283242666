import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Layout, { FirstContainer, SecondContainer } from "./Layout";
import axisIcon from "./../images/axis-icon.svg";
import axisIconDisabled from "./../images/axis-icon-disabled.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axisOptions from "./data/axisOptions.json";

const useStyles = makeStyles((theme) => ({
  container: { padding: 24 },
  header: { marginBottom: 20 },
  textContainer: { alignSelf: "flex-start" },
  text: { textAlign: "left", fontSize: "15px !important" },
  disabled: { opacity: 0.5 },
  axisOption: { position: "relative", margin: "8px 0 " },
  axisOptionText: { padding: "0 8px" },
  axisOptionTextActive: { textDecoration: "underline" },
  icon: { height: 48 },
  "@media (min-width: 600px)": {
    icon: { height: 30 },
    axisOption: { height: 40, marginTop: "8px 0 0" },
    text: { fontSize: "9px !important" },
  },
}));

export default function AxisSelection({ nextStep, handleAxisSelection }) {
  const classes = useStyles();
  const isNotMobile = useMediaQuery("(min-width:600px)");

  const handleClick = (axisOption) => {
    nextStep();
    handleAxisSelection(axisOption);
  };

  return (
    <Layout>
      <FirstContainer>
        <Typography variant="h6" gutterBottom>
          CHOOSE A VIOLENCE AXIS
        </Typography>
        <Typography>{`Browse data sets available in black${
          isNotMobile ? " >" : "."
        }`}</Typography>
      </FirstContainer>
      <SecondContainer>
        <Grid container className={classes.container}>
          {isNotMobile && (
            <Grid className={classes.header}>
              <Typography className={classes.text}>*available</Typography>
              <Typography
                className={`${classes.text} ${classes.disabled}`}
                gutterBottom
              >
                *coming soon
              </Typography>
            </Grid>
          )}
          <Grid container direction="row">
            {axisOptions.map(({ id, text, disabled }) => (
              <Grid
                key={id}
                container
                item
                sm={6}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                className={`${classes.axisOption} ${
                  disabled && classes.disabled
                }`}
              >
                <ButtonBase
                  disabled={disabled}
                  onClick={() => {
                    handleClick(id);
                  }}
                >
                  <Grid item>
                    <img
                      src={disabled ? axisIconDisabled : axisIcon}
                      alt="axis icon"
                      className={classes.icon}
                    />
                  </Grid>
                  <Grid item className={classes.textContainer}>
                    <Typography
                      className={`${classes.text} ${classes.axisOptionText} ${
                        !disabled && classes.axisOptionTextActive
                      }`}
                    >
                      {text}
                    </Typography>
                  </Grid>
                </ButtonBase>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </SecondContainer>
    </Layout>
  );
}
