import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Layout, { FirstContainer, SecondContainer } from "./Layout";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  container: { paddingBottom: "52px !important" },
  buttonContainer: { position: "absolute", left: 24, bottom: 24 },
  button: {
    borderRadius: 0,
    border: `1px solid ${theme.palette.secondary.main}`,
    maxWidth: 140,
    margin: "4px 8px 6px 0",
    padding: "2px 0 2px 4px",
    justifyContent: "flex-start",
  },
  blackButton: { border: `1px solid ${theme.palette.primary.dark}` },
  label: { width: "min-content", lineHeight: 1 },
  text: { marginBottom: 12 },
  "@media (min-width: 600px)": {
    button: {
      maxWidth: 100,
    },
  },
}));

export default function Welcome({ nextStep }) {
  const classes = useStyles();
  const isNotMobile = useMediaQuery("(min-width:600px)");

  return (
    <Layout>
      <FirstContainer>
        <div className={classes.container}>
          <Typography variant="h6" className={classes.text}>
            WELCOME TO THE AXIS OF MODERN VIOLENCE REMINDS.
          </Typography>
          <Typography className={classes.text}>
            The Axis of Modern Violence Reminds visualizes the intersections of otherness and sameness, oppression and depression, to form a landscape.
          </Typography>
          <Typography className={classes.text}>
            The Axis allows feeding different data sets for continuous interpretation of an ever-changing landscape as it relates to different social phenomena. What matters is your experience within the violence landscape.
          </Typography>
          <Typography className={classes.text}>
            You are invited to explore the landscape of violence from a personal plane. All you need to do is to complete a few questions that will allow you to:
          </Typography>
          <Typography variant="body2">rethink data,</Typography>
          <Typography variant="body2">re-signify experience,</Typography>
          <Typography variant="body2">
            overlap violence in the symbolic register with violence in the real;
          </Typography>
          <Typography variant="body2" className={classes.text} gutterBottom>
            make it personal.
          </Typography>
          <Grid
            container
            direction="column"
            className={classes.buttonContainer}
          >
            <Button
              variant="outlined"
              className={classes.button}
              classes={{ label: classes.label }}
              onClick={nextStep}
            >
              GO
            </Button>
          </Grid>
        </div>
      </FirstContainer>
      {isNotMobile && <SecondContainer showAxis />}
    </Layout>
  );
}
