import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      "Ubuntu",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h4: {
      fontFamily: [
        "DIN W01 Regular",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    h6: {
      fontFamily: [
        "DIN W01 Regular",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      fontSize: 20,
      lineHeight: 1.2,
      "@media (min-width: 600px)": { fontSize: 12 },
    },
    subtitle1: {
      fontFamily: [
        "DIN W01 Bold",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      fontSize: 20,
      lineHeight: 1.2,
      "@media (min-width: 600px)": { fontSize: 12 },
    },
    body1: {
      fontFamily: [
        "DIN W01 Regular",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      fontSize: 18,
      lineHeight: 1.2,
      "@media (min-width: 600px)": { fontSize: 11 },
    },
    body2: {
      fontFamily: [
        "DIN W01 Bold",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      fontSize: 18,
      lineHeight: 1.2,
      "@media (min-width: 600px)": { fontSize: 11 },
    },
    button: {
      fontFamily: [
        "DIN W01 Regular",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      fontSize: 18,
      "@media (min-width: 600px)": { fontSize: 12 },
    },
  },
  palette: {
    background: {
      default: "#fff",
    },
    primary: {
      light: "#e6e2e2",
      main: "#333333",
      dark: "#000",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fee",
      main: "#f11",
      contrastText: "#fff",
    },
  },
});

export default theme;
