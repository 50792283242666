import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Wizard from "./components/Wizard";
import ReadMore from "./components/ReadMore";

const MainRouter = withRouter(({ location }) => {
  return (
    <Switch>
      <Route path="/" exact component={Wizard} />
      <Route path="/readmore/:axis" component={ReadMore} />
    </Switch>
  );
});

export default MainRouter;
