import "./App.css";
import { Wizard } from "./components/Wizard";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import MainRouter from "./MainRouter";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <div className="App">
          <MainRouter />
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
/* 
state: {
  step:
  fields:
}
 methods: {
   nextStep(),
   prevState(),
   handleChange()
 }
quizForm
|
steps
*/
