module.exports = [
  "VIOLENCE IS ONE OF THOSE THINGS<br />THAT WILL NOT DISAPPEAR.",
  "IT ADAPTS TO THE<br />LANDSCAPE AT HAND;",
  "IF WE ACCEPT,<br />THAT BRUTE FORCE HAS LOST<br />ITS SCENIC VALUE,",
  "WE ALSO NEED TO RECOGNIZE<br />THAT NEW FORMS OF MEDIATED<br />OPPRESSIVE BEHAVIOR<br />HAVE EMERGED.",
  "IF OPPRESSION<br />WILL EVER DISAPPEAR,<br />VIOLENCE WILL BUILD<br />INTO DEPRESSION:",
  "THE DESTRUCTIVE PRESSURE<br />THAT COMES NOT FROM THE OTHER<br />BUT FROM WITHIN.",
  "PERHAPS<br />THE TIME IN WHICH THERE<br />WAS SUCH A THING AS THE OTHER<br />IS OVER.",
  "THE OTHER AS A SECRET,<br />TEMPTATION, HELL<br />AND PAIN DISAPPEAR, :(",
];
