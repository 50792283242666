import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./../theme";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Typewriter from "typewriter-effect";

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    height: "100vh",
    boxSizing: "border-box",
  },
  container: {
    height: "calc(100vh - 48px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  typography: {
    fontWeight: 700,
    marginTop: "calc(50vh - 164px)",
  },
  button: {
    position: "absolute",
    backgroundColor: theme.palette.primary.dark,
    alignSelf: "flex-end",
    bottom: 60,
    borderRadius: 0,
    padding: "8px 32px 10px",
    letterSpacing: "2px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "none",
    },
  },
  "@media (min-width: 600px)": {
    outerContainer: {
      border: `24px solid ${theme.palette.primary.dark}`,
    },
  },
}));

export default function Intro({ nextStep, message }) {
  const classes = useStyles();
  const [finished, setFinished] = useState(false);

  const handleContinue = (e) => {
    e.preventDefault();
    setFinished(true);
    setTimeout(() => {
      setFinished(false);
    }, 1);
    nextStep();
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.outerContainer}>
        <Container maxWidth="md" className={classes.container}>
          {!finished && (
            <Typography
              className={classes.typography}
              color="primary"
              variant="h4"
              component="h1"
            >
              <Typewriter
                options={{
                  delay: 60,
                }}
                onInit={(typewriter) => {
                  typewriter.typeString(message).start();
                }}
              />
            </Typography>
          )}
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleContinue}
          >
            continue
          </Button>
        </Container>
      </div>
    </ThemeProvider>
  );
}
