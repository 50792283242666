import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Markup } from "interweave";
import axis1 from "./data/axis1.json";
import axis2 from "./data/axis2.json";

const axisObj = {
  axis1: axis1,
  axis2: axis2,
};

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    height: "100vh",
    boxSizing: "border-box",
  },
  container: { marginTop: 32 },
  "@media (min-width: 600px)": {
    outerContainer: {
      border: `24px solid ${theme.palette.primary.dark}`,
    },
  },
  text: {
    textAlign: "left",
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
}));

export default function ReadMore({ match }) {
  const classes = useStyles();
  const textArr = axisObj[match.params.axis].readMoreText;

  return (
    <div className={classes.outerContainer}>
      <Container maxWidth="sm" className={classes.container}>
        {textArr.map(({ text, bold }, index) =>
          bold ? (
            <div key={index}>
              <Typography variant="subtitle1" className={classes.text}>
                <strong>
                  <Markup noWrap content={text} />
                </strong>
              </Typography>
              <br />
            </div>
          ) : (
            <div key={index}>
              <Typography className={classes.text}>
                <Markup noWrap content={text} />
              </Typography>
              <br />
            </div>
          )
        )}
      </Container>
    </div>
  );
}
