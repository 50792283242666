import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { YAxis, LineChart, Line } from "recharts";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Layout, { FirstContainer, SecondContainer } from "./Layout";
import findGraphOffset from "./../utils/findGraphOffset";

const useStyles = makeStyles((theme) => ({
  container: { width: "100%", paddingBottom: 40 },
  textWithBackground: {
    backgroundColor: "#F7F7F8",
    width: "100%",
    transform: "translateX(-24px)",
    padding: "8px 24px 8px 24px",
    margin: "8px 0",
  },
  questionContainer: { minHeight: 80 },
  question: {
    textAlign: "left",
    minHeight: 80,
  },
  button: {
    display: "block",
    width: "46%",
    fontSize: 18,
    textTransform: "lowercase",
    textAlign: "left",
    lineHeight: 1,
    borderRadius: 0,
    border: `1px solid ${theme.palette.secondary.main}`,
    margin: "8px 8px 16px 0",
    padding: "1px 0 2px 2px",
    justifyContent: "flex-start",
    "@media (hover: hover)": {
      "&:hover": {
        border: `1px solid ${theme.palette.secondary.main}`,
        backgroundColor: theme.palette.secondary.light,
      },
    },
    "&:hover": {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  chart: { transform: "translate(17px, 12px)" },
  "@media (min-width: 600px)": {
    container: { paddingBottom: 0 },
    textWithBackground: {
      width: 374,
      padding: "8px 0 8px 24px",
    },
    questionContainer: { minHeight: 48 },
    question: {
      width: 200,
      minHeight: 48,
    },
    button: {
      fontSize: 11,
      width: 84,
      maxWidth: 100,
      margin: "4px 8px 8px 0",
    },
    chart: { transform: "translate(17px, 55px)" },
  },
}));

export default function Quiz({
  quizStep,
  nextStep,
  handleChange,
  axisName,
  question,
  options,
  values,
  graphDomain,
}) {
  const classes = useStyles();
  const [showQuestion, setShowQuestion] = useState(true);
  const isNotMobile = useMediaQuery("(min-width:600px)");
  const graphHeight = isNotMobile ? 312 : 340;

  const handleContinue = (value) => {
    setShowQuestion(false);
    setTimeout(() => {
      setShowQuestion(true);
    }, 1);
    handleChange(value);
    nextStep();
  };

  return (
    <Layout>
      {/*////////// FORM ////////*/}
      <FirstContainer>
        <Typography variant="h6">QUESTIONS</Typography>
        <Typography variant="subtitle1" className={classes.textWithBackground}>
          {axisName}
        </Typography>
        <div className={classes.questionContainer}>
          {showQuestion && (
            <Typography className={classes.question}>{question}</Typography>
          )}
        </div>
        {options.map((option) => (
          <Button
            key={option.label}
            className={classes.button}
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => {
              handleContinue(option.value);
            }}
          >
            {option.label}
          </Button>
        ))}
      </FirstContainer>
      {/*////////// GRAPH ////////*/}
      <SecondContainer showAxis>
        <div className={classes.container}>
          <LineChart
            style={{
              transform: `translateY(${findGraphOffset(
                graphHeight,
                graphDomain,
                isNotMobile
              )}px)`,
            }}
            className={classes.chart}
            width={isNotMobile ? 364 : window.innerWidth - 60}
            height={isNotMobile ? 312 : 340}
            data={values.map((value, index) => {
              if (quizStep > index) {
                return { name: "", value: value.selected };
              } else {
                return {};
              }
            })}
          >
            <Line
              type="linear"
              dataKey="value"
              stroke="red"
              strokeWidth="1.5"
            />
            <YAxis hide domain={graphDomain} />
          </LineChart>
        </div>
      </SecondContainer>
    </Layout>
  );
}
